// Gatsby v4 wrapPageElement with useQueryParams
const React = require('react');
const { navigate } = require('gatsby');
const { QueryParamProvider } = require('use-query-params');

exports.wrapPageElement = ({ element, props }) => {
  const generatePath = (location) => {
    return location.pathname + location.search;
  };

  const history = {
    push: (location) => {
      navigate(generatePath(location), {
        replace: false,
        state: location.state,
      });
    },
    replace: (location) => {
      navigate(generatePath(location), {
        replace: true,
        state: location.state,
      });
    },
  };

  return (
    <QueryParamProvider history={history} location={props.location}>
      {element}
    </QueryParamProvider>
  );
};

// Control how the page scroll updates on page load.
exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const isPropsUndefined = !prevRouterProps || !routerProps;
  if (isPropsUndefined) return true;

  const prevPathname = prevRouterProps?.location?.pathname;
  const currPathname = routerProps?.location?.pathname;
  const prevOrigin = prevRouterProps?.location?.origin;
  const currOrigin = routerProps?.location?.origin;

  const hasSamePathAsBefore = prevPathname === currPathname;
  const hasSameOriginAsBefore = prevOrigin === currOrigin;

  const shouldMaintainScrollPosition =
    hasSamePathAsBefore && hasSameOriginAsBefore;

  return !shouldMaintainScrollPosition;
};
